import React, { useState, useId, useEffect } from 'react';
import style from './DataSetsFilterAccordion.module.scss';
import PropTypes from 'prop-types';
import icon from 'Assets/svgSprite.svg';

/**
 * DataSetsFilterAccordion.jsx
 *
 * @summary This component is page view for accordion of filters for data sets page.
 *
 * @param {Object} props - Component props.
 * @prop {Node} props.children - Children nodes to display when accordion is open
 * @prop {String} props.title - Title of accordion
 * @prop {Boolean} [props.isRequired] - Shows the red asterix in the title if given as true
 * @prop {Boolean} [props.isDisabled] - Disable accordion
 * @prop {String} [props.styleOverride] - Allows class value to be added to override styles
 */
function DataSetsFilterAccordion({ children, title, isRequired, isDisabled, styleOverride, darkTheme }) {
  const [isOpen, setIsOpen] = useState(false);
  const buttonId = useId();
  const contentId = useId();

  useEffect(() => {
    if (isDisabled) {
      setIsOpen(false);
    }
  }, [isDisabled]);

  return (
    <div className={[style.accordion].join(' ')}>
      <button
        id={buttonId}
        aria-controls={contentId}
        aria-expanded={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        disabled={isDisabled}
        className={[style.triangle, darkTheme ? '' : 'lightFill', style.accordionButton, isOpen ? style.isOpen : '', styleOverride].join(' ')}
        title={`${isOpen ? 'close' : 'open'} accordion`}>
        <span className={[isRequired ? style.required : ''].join(' ')}>{title}</span>
        <svg>
          <use href={`${icon}#triangle`} />
        </svg>
      </button>
      <div id={contentId} aria-labelledby={buttonId} className={[style.accordionDropdown, isOpen ? style.contentShow : ''].join(' ')}>
        {children}
      </div>
    </div>
  );
}

DataSetsFilterAccordion.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  styleOverride: PropTypes.string,
  darkTheme: PropTypes.bool,
};

export default DataSetsFilterAccordion;
