import React, { Suspense, useState, useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import style from './PublicationsList.module.scss';
import icons from 'Assets/svgSprite.svg';
import { useRouteLoaderData, Await, useSearchParams, Link, useLocation } from 'react-router-dom';
import { CONSTANTS, DROPDOWN_OPTIONS_MULTI, PAGE_NOT_FOUND_TITLE, PATHS, PUBLICATIONS_LOCAL_STORAGE_KEY, PUBLICATION_TYPE, SOMETHING_WENT_WRONG, URL_QUERY_KEYS } from 'Constants';
import { AlphabetGroupFilterList, Filter, FilterGroupDropdown, LoadingMenu, PageHead, Pagination, SearchFilterList, StandardFilterList, Tag } from 'Components';
import { FilterLoading, PublicationsLoading, StandardPage } from 'Pages';
import {
  arraysAreEqualByProperty,
  convertFilterUrlToApi,
  debounce,
  detectViewMode,
  getFilterGroupDisplayName,
  groupFiltersForUrl,
  innerPageScroll,
  removeFromLocalStorage,
  updateEntireList,
  updateMultiSelection,
} from 'Utils';
import { FILTERABLE_GROUPS, SEARCH_API_CONSTANTS, SEARCH_API_FUNCTION } from 'Constants/Search';
import { FILTER_API_FAILED } from 'Constants/StaticContents';
import { fetchPublications } from 'Utils/Loaders';
import { MAX_PUBLICATION_PER_PAGE, PUBLICATIONS_LIST_ID } from 'Constants/Publications';
import DefaultCoverImage from 'Pages/AnnualReportCover/DefaultCoverImage';

/**
 * PublicationsList.jsx
 *
 * @summary This component is page view for publications list page.
 *
 */
export default function PublicationsList() {
  const { publications } = useRouteLoaderData(PATHS.PUBLICATIONS_LIST.ID);
  const { homepage } = useRouteLoaderData(PATHS.HOME.ID);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [previouslyAppliedFilters, setPreviouslyAppliedFilters] = useState([]); // used to optimise filters by only calling api on apply filter when necessary
  const [multiSelectOptions, setMultiSelectOptions] = useState(DROPDOWN_OPTIONS_MULTI);
  const [publicationList, setPublicationList] = useState([]);
  const [publicationCoverImgLoad, setPublicationCoverImgLoad] = useState([]); // used to track loading state of cover page images loading
  const [totalItems, setTotalItems] = useState(null);
  const [urlParams, setUrlParams] = useSearchParams();
  const [hasErrored, setHasErrored] = useState(false);
  const [loadingPublications, setLoadingPublications] = useState(false);
  const [selectAllState, setSelectAllState] = useState(false);
  const [viewMode, setViewMode] = useState(detectViewMode());
  const locationData = useLocation();
  const { pathname, search: searchLocationData } = locationData;

  useEffect(() => {
    const detectViewPortWidthChange = debounce(() => setViewMode(detectViewMode()));
    window.addEventListener('resize', detectViewPortWidthChange);

    return () => {
      window.removeEventListener('resize', detectViewPortWidthChange);
    };
  });

  const updateSingleMultiSelect = (selectedOption, filterGroup) => {
    // Note: Relies on there being unique return values across ALL filter values
    updateMultiSelection(selectedOption, filterGroup, multiSelectOptions, setMultiSelectOptions, selectedFilters, setSelectedFilters);
  };

  const updateMultipleMultiSelect = (listOfUpdatedOptions, filterGroup) => {
    updateEntireList(listOfUpdatedOptions, filterGroup, multiSelectOptions, setMultiSelectOptions, selectedFilters, setSelectedFilters);
  };

  const removeFilter = (dropdownOption) => {
    // Filter out dropdown option from currently select filter values
    setSelectedFilters((currentSelected) =>
      currentSelected.filter((option) => {
        return option.returnValue !== dropdownOption.returnValue;
      }),
    );

    // Unselect multiSelect option
    Object.keys(multiSelectOptions).map((key) => {
      if (Array.isArray(multiSelectOptions[key])) {
        multiSelectOptions[key].forEach((option) => {
          if (option.returnValue === dropdownOption.returnValue) {
            dropdownOption.selected = false;
            return;
          }
        });
      }
    });
  };

  const clearFilter = () => {
    // Group filters by category (to be applied for search api)
    const groupedFilters = groupFiltersForUrl(selectedFilters);
    setUrlParams((prevParams) => {
      Object.keys(groupedFilters).forEach((key) => {
        prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${key}`, ''); // reset all filter values in url
      });
      return prevParams;
    });

    // Unselect multiSelect option
    Object.keys(multiSelectOptions).map((key) => {
      if (Array.isArray(multiSelectOptions[key])) {
        multiSelectOptions[key].forEach((option) => {
          option.selected = false;
        });
      }
    });
    setSelectedFilters([]);

    // Only re-call api when clear clicked if there are previously any filters already applied (otherwise just clears without calling api)
    if (Object.keys(groupedFilters).length !== 0 && previouslyAppliedFilters.length > 0) {
      setPreviouslyAppliedFilters([]);
      fetchNewPublications(1, true);
    }
  };

  const applyFilter = () => {
    // Don't apply filter if previous filters have not changed
    if (!arraysAreEqualByProperty(selectedFilters, previouslyAppliedFilters, 'returnValue')) {
      // Always remove previous values of local storage when filter gets applied
      removeFromLocalStorage(PUBLICATIONS_LOCAL_STORAGE_KEY);

      // Group filters by category (to be applied for search api)
      const groupedFilters = groupFiltersForUrl(selectedFilters);

      // Set url params for fitlers (all filters are denoted with a prefix of '$')
      setUrlParams((prevParams) => {
        Object.keys(groupedFilters).forEach((key) => {
          prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${key}`, groupedFilters[key]);
        });
        Object.keys(multiSelectOptions).forEach((key) => {
          if (!Object.keys(groupedFilters).includes(key)) {
            prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${key}`, ''); // reset all other keys
          }
        });
        prevParams.set(URL_QUERY_KEYS.PAGE_NUMBER, '1');
        return prevParams;
      });

      const filters = [];
      Object.keys(groupedFilters).forEach((key) => {
        filters.push(SEARCH_API_FUNCTION.FILTER_IN(key, groupedFilters[key]?.split(URL_QUERY_KEYS.FILTER_VALUES_SPLIT_BY) || []));
      });

      // Combines all filters into expression to be used in search api
      const filterString = SEARCH_API_FUNCTION.COMBINE_EXPRESSION(filters, SEARCH_API_CONSTANTS.AND);
      setPreviouslyAppliedFilters(selectedFilters);
      fetchNewPublications(1, true, filterString);
    }
  };

  const isARType = (publicationType) => {
    // checks if current type is Annual Report
    return publicationType.toLowerCase() === PUBLICATION_TYPE.ANNUAL_REPORT.TAG.toLowerCase();
  };

  /**
   * To handle page number changes for publications. Scrolls up to beginning of list and calls api/local storage to get publication data
   */
  const onPageChange = (page) => {
    innerPageScroll(PUBLICATIONS_LIST_ID);
    fetchNewPublications(page, false, convertFilterUrlToApi(urlParams));
  };

  /**
   * Add loading image state (only publications with cover images will be in loading state)
   * @param {{coverImageSrc: String}[]} publications - List of publications
   * @returns {Array} An array of boolean values set to true for any publications with images (to display loading animation)
   */
  const imageLoadingState = (publications) => {
    const loadingState = publications.map((publication) => {
      const imageData = { loading: false, fail: false };
      if (publication.coverImageSrc) {
        return { ...imageData, loading: true };
      }
      return imageData;
    });
    return loadingState;
  };

  /**
   * Set loading of image back to false when it finishes loading (in order to stop showing animation)
   * @param {Number} index - Index of publication list item
   */
  const onImageLoad = (index) => {
    setPublicationCoverImgLoad((prevState) => {
      const newLoadingState = prevState.map((state, i) => {
        if (index === i) {
          return { ...state, loading: false };
        }
        return state;
      });
      return newLoadingState;
    });
  };

  /**
   * Set error of image to true if it fails to load
   * @param {Number} index - Index of publication list item
   */
  const onImageError = (index) => {
    setPublicationCoverImgLoad((prevState) => {
      const newLoadingState = prevState.map((state, i) => {
        if (index === i) {
          return { loading: false, fail: true };
        }
        return state;
      });
      return newLoadingState;
    });
  };

  /**
   * Calls the loader for publications to fetch new publications
   * Used when page changes, or when search and filter values are entered
   * @param {Number} pageNum - Page number to
   * @param {Boolean} rerunApi - Specifies whether to override rerun api (true). Otherwise, it will take from local storage if found
   * @param {String} filters - Filter values, should be created in OData format using `SEARCH_API_FUNCTION` methods
   * @param {String} searchQuery - Query string to search publications with
   */
  const fetchNewPublications = async (pageNum, rerunApi, filters, searchQuery) => {
    let newPublications = [];
    let totalCount = 0;

    // Takes the current search term given (if not found will take from url)
    let searchTerm;
    if (searchQuery !== null && searchQuery !== undefined) {
      searchTerm = searchQuery;
    } else {
      searchTerm = urlParams.get(URL_QUERY_KEYS.SEARCH_TERM) || '';
    }

    if (rerunApi) {
      removeFromLocalStorage(PUBLICATIONS_LOCAL_STORAGE_KEY); // rerunning api should remove all data from local (used for either new search term or filters)
    }

    try {
      setLoadingPublications(true);
      const { publications, totalItems } = await fetchPublications(pageNum, rerunApi, filters, searchTerm);
      // FIXME: HOTJAR INTEGRATION - After MVP 2 release, at the start of MVP3, hotjar need to be removed as this was only for research purpose by designer between MVP2 and MVP3
      hotjar.initialize(process.env.REACT_APP_HOTJAR_ID);
      // Error can be returned here, hence don't update publications and count if not found
      if (publications && totalItems) {
        newPublications = publications || [];
        totalCount = totalItems;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPublications(false);
      setPublicationList(newPublications);
      setTotalItems(totalCount);
      setPublicationCoverImgLoad(imageLoadingState(newPublications)); // set all image loading to true initially
    }
  };

  return (
    <>
      {!hasErrored && (
        <PageHead pageTitle={PATHS.PUBLICATIONS_LIST.TITLE} keepBackgroundImage>
          <div className={style.bannerHead}>
            <p>Find Annual Reports, Corporate Plans and Portfolio Budget Statements for all Commonwealth entities and companies.</p>
          </div>
        </PageHead>
      )}
      {!hasErrored && (
        <Suspense fallback={<FilterLoading />}>
          <Await
            resolve={homepage}
            errorElement={
              <Filter filterTitle="Filter publications" isDisabled removeFilterFunction={removeFilter} clearFilterFunction={clearFilter} applyFilterFunction={applyFilter}>
                {FILTER_API_FAILED}
              </Filter>
            }>
            {(loadedData) => {
              const { filterOptions, filterGroupValues } = loadedData; // home page data (which is needed for filter options)
              if (!filterOptions || !filterGroupValues) {
                return (
                  <Filter filterTitle="Filter publications" isDisabled removeFilterFunction={removeFilter} clearFilterFunction={clearFilter} applyFilterFunction={applyFilter}>
                    {FILTER_API_FAILED}
                  </Filter>
                );
              }

              useEffect(() => {
                // FIXME: HOTJAR INTEGRATION - After MVP 2 release, at the start of MVP3, hotjar need to be removed as this was only for research purpose by designer between MVP2 and MVP3
                hotjar.initialize(process.env.REACT_APP_HOTJAR_ID);
              }, []);

              useEffect(() => {
                const selectedFilterList = [];

                // Add filtered options from url (if any)
                Object.keys(filterOptions).forEach((key) => {
                  // Note: Assumes that values are split by ';'
                  const groupInUrl = urlParams.get(`${URL_QUERY_KEYS.FILTER_DENOTE}${key}`)?.split(URL_QUERY_KEYS.FILTER_VALUES_SPLIT_BY) || [];
                  if (groupInUrl) {
                    filterOptions[key].forEach((dropdownOption) => {
                      if (groupInUrl.includes(dropdownOption.returnValue)) {
                        dropdownOption.selected = true;
                        selectedFilterList.push(dropdownOption);
                      } else {
                        dropdownOption.selected = false;
                      }
                    });
                  }
                });

                setMultiSelectOptions(filterOptions);
                setSelectedFilters(selectedFilterList);
                setPreviouslyAppliedFilters(selectedFilterList);
              }, [pathname, searchLocationData]);

              return (
                <Filter
                  filterTitle="Filter publications"
                  filterLabel={`Showing ${publicationList.length} of ${totalItems || 0} results`}
                  selectedFilters={selectedFilters}
                  removeFilterFunction={removeFilter}
                  clearFilterFunction={clearFilter}
                  applyFilterFunction={applyFilter}
                  disableApplyButton={arraysAreEqualByProperty(selectedFilters, previouslyAppliedFilters, 'returnValue')}>
                  {Object.keys(filterOptions).map((groupOption, index) => {
                    const filterGroupDisplayName = getFilterGroupDisplayName(groupOption, filterGroupValues);
                    // Default filter is standard filter list
                    let filterListComponent = (
                      <StandardFilterList
                        dropdownOptions={multiSelectOptions[groupOption] || []}
                        selectOptionFunction={(option) => updateSingleMultiSelect(option, groupOption)}></StandardFilterList>
                    );
                    if (groupOption?.toLowerCase() === FILTERABLE_GROUPS.PORTFOLIO.VALUE.toLowerCase()) {
                      // Use SearchFilterList component for 'Portfolio' filter
                      filterListComponent = (
                        <SearchFilterList
                          dropdownOptions={multiSelectOptions[groupOption] || []}
                          selectOptionFunction={(option) => updateSingleMultiSelect(option, groupOption)}
                          searchPlaceholder={`Enter your ${filterGroupDisplayName.toLowerCase()}`}></SearchFilterList>
                      );
                    } else if (groupOption?.toLowerCase() === FILTERABLE_GROUPS.ENTITY.VALUE.toLowerCase()) {
                      // Use Alphabet Group Filter List for 'Entity and companies' filter
                      filterListComponent = (
                        <AlphabetGroupFilterList
                          dropdownOptions={multiSelectOptions[groupOption] || []}
                          selectOptionFunction={(option) => updateSingleMultiSelect(option, groupOption)}
                          selectMultipleOptionFunction={(multipleOptions) => updateMultipleMultiSelect(multipleOptions, groupOption)}
                          searchPlaceholder={`Enter your ${filterGroupDisplayName.toLowerCase()}`}
                          selectAllState={selectAllState}
                          setSelectAllState={setSelectAllState}></AlphabetGroupFilterList>
                      );
                    }

                    return (
                      <FilterGroupDropdown key={`filter-group-dropdown-${groupOption}-${index}`} filterGroupTitle={groupOption} filterGroupDisplayName={filterGroupDisplayName}>
                        {filterListComponent}
                      </FilterGroupDropdown>
                    );
                  })}
                </Filter>
              );
            }}
          </Await>
        </Suspense>
      )}
      <Suspense fallback={<PublicationsLoading />}>
        <Await
          resolve={publications}
          errorElement={
            <StandardPage setErrorFunction={() => setHasErrored(true)} title={PAGE_NOT_FOUND_TITLE}>
              {SOMETHING_WENT_WRONG}
            </StandardPage>
          }>
          {(loadedData) => {
            let publicationData = [];
            let fullCount = 0;

            // only update publication data and fullcount if found
            if (loadedData?.publications && loadedData.totalItems) {
              publicationData = loadedData.publications;
              fullCount = isNaN(loadedData.totalItems) ? 0 : loadedData.totalItems;
            }

            useEffect(() => {
              setPublicationList(publicationData);
              setPublicationCoverImgLoad(imageLoadingState(publicationData)); // set all loading states to 'true' initially
              setTotalItems(fullCount);
            }, []);

            // Find total pages (uses totalItems but will use fullCount on first load)
            const totalPages = Math.ceil((totalItems !== null ? totalItems : fullCount || 0) / MAX_PUBLICATION_PER_PAGE);

            return (
              <>
                {loadingPublications ? (
                  <PublicationsLoading />
                ) : (
                  <ol id={PUBLICATIONS_LIST_ID} className={style.publicationsContainer}>
                    {publicationList.map((publication, index) => {
                      return (
                        <li key={`${publication.publicationTitle}-item-${index}`} className={[style.publicationCard, 'card', 'listItem'].join(' ')}>
                          <span className="portfolioInfo">
                            {publication.publicationType === PUBLICATION_TYPE.PORTFOLIO_BUDGET_STATEMENT.TAG && 'Portfolio: '}
                            {publication.publicationHeader && publication.publicationHeaderLink && (
                              <Link className="headerLink" to={`/${publication.publicationHeaderLink}`}>
                                {publication.publicationHeader}
                              </Link>
                            )}
                          </span>
                          <Link
                            // TODO: change PBS and CP publications to link to correct url
                            to={(isARType(publication.publicationType) ? '/' : '') + publication.publicationLink}
                            target={!isARType(publication.publicationType) ? '_blank' : '_self'}>
                            <span className="limitTextLines">
                              {Array.isArray(publication?.tags) && publication?.tags?.length > 0 && (
                                <span className="tags">
                                  {publication.tags.map((tag, j) => (
                                    <Tag key={`${publication.publicationTitle}-${index}-tag-${j}`} displayText={tag.display} type={tag.type} />
                                  ))}
                                </span>
                              )}
                              <span className="cardTitle limitTextLines" style={{ '--MAX-LINE': 3 }}>
                                {publication.publicationTitle}
                                {/* Only show 'open in new tab' icon if it is PBS or CP type (not AR) */}
                                {!isARType(publication.publicationType) && (
                                  <svg>
                                    <use href={icons + '#open-in-new-tab'} />
                                  </svg>
                                )}
                              </span>
                            </span>
                            {/* Shows loading for images until image finishes loading */}
                            <LoadingMenu
                              numberOfItems={viewMode !== CONSTANTS.VIEW_MODE.MOBILE ? 11 : 5}
                              className={[publicationCoverImgLoad[index]?.loading ? style.display : style.hide, 'imgLoading'].join(' ')}
                            />
                            {!publication.coverImageSrc || publicationCoverImgLoad[index]?.fail ? (
                              <div className="imgLoading">
                                <DefaultCoverImage reportTitle={publication.publicationTitle} entityName={publication.publicationHeader} />
                              </div>
                            ) : (
                              <img
                                className={publicationCoverImgLoad[index]?.loading || publicationCoverImgLoad[index]?.fail ? style.hide : style.display}
                                src={publication.coverImageSrc || null}
                                alt=""
                                role="presentation"
                                onLoad={() => onImageLoad(index)}
                                onError={() => onImageError(index)}
                              />
                            )}
                          </Link>
                        </li>
                      );
                    })}
                  </ol>
                )}
                <div className={loadingPublications ? style.hidePagination : ''}>
                  <Pagination totalPages={totalPages} onPageChange={onPageChange} />
                </div>
              </>
            );
          }}
        </Await>
      </Suspense>
    </>
  );
}
