import React, { Suspense, useState, useEffect, useRef } from 'react';
import { hotjar } from 'react-hotjar';
import parse from 'html-react-parser';
import icons from 'Assets/svgSprite.svg';
import { Await, Link, useRouteLoaderData, useSearchParams, useLocation } from 'react-router-dom';
import { AlphabetGroupFilterList, Filter, FilterGroupDropdown, LoadingMenu, PageHead, Pagination, SearchBar, SearchFilterList, StandardFilterList, Tag } from 'Components';
import {
  CONSTANTS,
  CONTENT_ITEM_TYPES,
  DROPDOWN_OPTIONS_MULTI,
  NO_RESULTS_FOUND,
  PAGE_NOT_FOUND_TITLE,
  PATHS,
  PUBLICATION_TYPE,
  SEARCH_PAGE_LOCAL_STORAGE_KEY,
  URL_QUERY_KEYS,
} from 'Constants';
import { FILTERABLE_GROUPS, SEARCH_API_CONSTANTS, SEARCH_API_FUNCTION, BODY_TYPES, SEARCH_API_URL } from 'Constants/Search';
import {
  annualReportUrl,
  arraysAreEqualByProperty,
  convertFilterUrlToApi,
  entityProfileUrl,
  getFilterGroupDisplayName,
  groupFiltersForUrl,
  innerPageScroll,
  portfolioProfileUrl,
  removeFromLocalStorage,
  updateEntireList,
  updateMultiSelection,
} from 'Utils';
import DefaultCoverImage from 'Pages/AnnualReportCover/DefaultCoverImage';
import DefaultEntityLogo from 'Assets/AusGovCrest-DefaultEntityLogo.svg';
import LightTree from 'Assets/Tree-Half-Light.svg';
import style from './SearchPage.module.scss';
import { MAX_SEARCH_RESULTS_PER_PAGE } from 'Constants/SearchPage';
import { fetchSearchResults } from 'Utils/Loaders';
import { isARType, isCPType, isPBSType, debounce, detectViewMode } from 'Utils';
import { FILTER_API_FAILED, SOMETHING_WENT_WRONG } from 'Constants/StaticContents';
import { FilterLoading, SearchPageLoading, StandardPage } from 'Pages';
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';

/**
 * SearchPage.jsx
 *
 * @summary This component is page view for search page.
 */
export default function SearchPage() {
  const { search, metadata } = useRouteLoaderData(PATHS.SEARCH.ID);
  const { homepage } = useRouteLoaderData(PATHS.HOME.ID);
  const [searchResultList, setSearchResultList] = useState([]);
  const [coverImgLoad, setCoverImgLoad] = useState([]); // used to track loading state of cover page images loading
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [previouslyAppliedFilters, setPreviouslyAppliedFilters] = useState([]); // used to optimise filters by only calling api on apply filter when necessary
  const [multiSelectOptions, setMultiSelectOptions] = useState(DROPDOWN_OPTIONS_MULTI);
  const [totalItems, setTotalItems] = useState(null);
  const [urlParams, setUrlParams] = useSearchParams();
  const [selectAllState, setSelectAllState] = useState(false);
  const [hasErrored, setHasErrored] = useState(false);
  const [loadingSearchResults, setLoadingSearchResults] = useState(false);
  const [viewMode, setViewMode] = useState(detectViewMode());
  const hasMounted = useRef(false);
  const locationData = useLocation();
  const { pathname, search: searchLocationData } = locationData;
  const appInsights = useAppInsightsContext();
  const trackSearch = useTrackEvent(appInsights, 'Search');
  const trackClick = useTrackEvent(appInsights, 'Click');

  const backgroundImage = {
    '--BACKGROUND-IMAGE': `url(${LightTree})`,
  };

  useEffect(() => {
    const detectViewPortWidthChange = debounce(() => setViewMode(detectViewMode()));
    window.addEventListener('resize', detectViewPortWidthChange);

    return () => {
      window.removeEventListener('resize', detectViewPortWidthChange);
    };
  });

  useEffect(() => {
    // refetch new search when new search term is added\
    if (hasMounted.current) {
      // only run this when it is not the first load
      fetchNewSearchResults(urlParams.get(URL_QUERY_KEYS.PAGE_NUMBER), false, urlParams.get(URL_QUERY_KEYS.SEARCH_TERM), convertFilterUrlToApi(urlParams));
    } else {
      hasMounted.current = true;
    }
  }, [urlParams.get(URL_QUERY_KEYS.SEARCH_TERM)]);

  const updateSingleMultiSelect = (selectedOption, filterGroup) => {
    // Note: Relies on there being unique return values across ALL filter values
    updateMultiSelection(selectedOption, filterGroup, multiSelectOptions, setMultiSelectOptions, selectedFilters, setSelectedFilters);
  };

  const updateMultipleMultiSelect = (listOfUpdatedOptions, filterGroup) => {
    updateEntireList(listOfUpdatedOptions, filterGroup, multiSelectOptions, setMultiSelectOptions, selectedFilters, setSelectedFilters);
  };

  const removeFilter = (dropdownOption) => {
    // Filter out dropdown option from currently select filter values
    setSelectedFilters((currentSelected) =>
      currentSelected.filter((option) => {
        return option.returnValue !== dropdownOption.returnValue;
      }),
    );

    // Unselect multiSelect option
    Object.keys(multiSelectOptions).map((key) => {
      if (Array.isArray(multiSelectOptions[key])) {
        multiSelectOptions[key].forEach((option) => {
          if (option.returnValue === dropdownOption.returnValue) {
            dropdownOption.selected = false;
            return;
          }
        });
      }
    });
  };

  const clearFilter = () => {
    // Group filters by category (to be applied for search api)
    const groupedFilters = groupFiltersForUrl(selectedFilters);
    setUrlParams((prevParams) => {
      Object.keys(groupedFilters).forEach((key) => {
        prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${key}`, ''); // reset all filter values in url
      });
      return prevParams;
    });

    // Unselect multiSelect option
    Object.keys(multiSelectOptions).map((key) => {
      if (Array.isArray(multiSelectOptions[key])) {
        multiSelectOptions[key].forEach((option) => {
          option.selected = false;
        });
      }
    });
    setSelectedFilters([]);

    // Only re-call api when clear clicked if there are previously any filters already applied (otherwise just clears without calling api)
    if (Object.keys(groupedFilters).length !== 0 && previouslyAppliedFilters.length > 0) {
      setPreviouslyAppliedFilters([]);
      fetchNewSearchResults(1, true);
    }
  };

  const applyFilter = () => {
    // Don't apply filter if previous filters have not changed
    if (!arraysAreEqualByProperty(selectedFilters, previouslyAppliedFilters, 'returnValue')) {
      // Always remove previous values of local storage when filter gets applied
      removeFromLocalStorage(SEARCH_PAGE_LOCAL_STORAGE_KEY);

      // Group filters by category (to be applied for search api)
      const groupedFilters = groupFiltersForUrl(selectedFilters);

      // Set url params for fitlers (all filters are denoted with a prefix of '$')
      setUrlParams((prevParams) => {
        Object.keys(groupedFilters).forEach((key) => {
          prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${key}`, groupedFilters[key]);
        });
        Object.keys(multiSelectOptions).forEach((key) => {
          if (!Object.keys(groupedFilters).includes(key)) {
            prevParams.set(`${URL_QUERY_KEYS.FILTER_DENOTE}${key}`, ''); // reset all other keys
          }
        });
        prevParams.set(URL_QUERY_KEYS.PAGE_NUMBER, '1');
        return prevParams;
      });

      const filters = [];
      Object.keys(groupedFilters).forEach((key) => {
        filters.push(SEARCH_API_FUNCTION.FILTER_IN(key, groupedFilters[key]?.split(URL_QUERY_KEYS.FILTER_VALUES_SPLIT_BY) || []));
      });

      // Combines all filters into expression to be used in search api
      const filterString = SEARCH_API_FUNCTION.COMBINE_EXPRESSION(filters, SEARCH_API_CONSTANTS.AND);
      setPreviouslyAppliedFilters(selectedFilters);
      fetchNewSearchResults(1, true, urlParams.get(URL_QUERY_KEYS.SEARCH_TERM) || '', filterString);
    }
  };

  /**
   * Call new search results for new search term added
   * Always set the page back to one on new search
   * @param {String} searchTerm - Term to search with
   */
  const searchTermUpdated = (searchTerm) => {
    setUrlParams((prevParams) => {
      prevParams.set(URL_QUERY_KEYS.PAGE_NUMBER, '1');
      prevParams.set(URL_QUERY_KEYS.SEARCH_TERM, searchTerm);
      return prevParams;
    });
  };

  /**
   * Add loading image state (only publications with cover images will be in loading state)
   * @param {{coverImageSrc: String}[]} searchResults - List of publications
   * @returns {Array} An array of boolean values set to true for any publications with images (to display loading animation)
   */
  const imageLoadingState = (searchResults) => {
    const loadingState = searchResults.map((result) => {
      const imageData = { loading: false, fail: false };
      if (result.coverImage) {
        return { ...imageData, loading: true };
      }
      return imageData;
    });
    return loadingState;
  };

  /**
   * Set loading of image back to false when it finishes loading (in order to stop showing animation)
   * @param {Number} index - Index of publication list item
   */
  const onImageLoad = (index) => {
    setCoverImgLoad((prevState) => {
      const newLoadingState = prevState.map((state, i) => {
        if (index === i) {
          return { ...state, loading: false };
        }
        return state;
      });
      return newLoadingState;
    });
  };

  /**
   * Set error of image to true if it fails to load
   * @param {Number} index - Index of publication list item
   */
  const onImageError = (index) => {
    setCoverImgLoad((prevState) => {
      const newLoadingState = prevState.map((state, i) => {
        if (index === i) {
          return { loading: false, fail: true };
        }
        return state;
      });
      return newLoadingState;
    });
  };

  /**
   * Get the search page link for individual search card
   * @param {Object} individualResult - Details about the search result (should include publicationType and isPortfolio)
   * @returns {String} - Link to the correct page
   *
   * - Adds publication and annual report base to annual report links
   * - Adds portfolio profile base to portfolio links
   * - TODO: Add CP and PBS links
   */
  const searchPageLink = (individualResult) => {
    if (individualResult) {
      const contentType = individualResult.type;

      if (contentType === CONTENT_ITEM_TYPES.GOVERNMENT_BODY.ID) {
        return `/${entityProfileUrl(individualResult.portfolio.urlSlug, individualResult.entity.urlSlug)}`;
      }

      if (contentType === CONTENT_ITEM_TYPES.PORTFOLIO.ID) {
        return `/${portfolioProfileUrl(individualResult.portfolio.urlSlug)}`;
      }

      if (isARType(contentType) || isReportChapter(contentType) || isReportSection(contentType)) {
        return `/${annualReportUrl(individualResult?.portfolio?.urlSlug, individualResult?.entity?.urlSlug, individualResult.targetLink)}`;
      }

      if (isCPType(contentType)) {
        return individualResult.targetLink;
      }

      if (isPBSType(contentType)) {
        return individualResult.targetLink;
      }

      if (contentType === CONTENT_ITEM_TYPES.STANDARD_PAGE.ID) {
        return `/${individualResult.targetLink}`;
      }
    }

    return '';
  };

  const entitiesLink = (individualResult, overrideLink) => {
    if (individualResult.type === PUBLICATION_TYPE.PORTFOLIO_BUDGET_STATEMENT.VALUE) {
      if (individualResult.portfolio.urlSlug) {
        return `/${portfolioProfileUrl(individualResult.portfolio.urlSlug)}`;
      }
    }

    if (
      [PUBLICATION_TYPE.ANNUAL_REPORT.VALUE, PUBLICATION_TYPE.CORPORATE_PLAN.VALUE, CONTENT_ITEM_TYPES.REPORT_CHAPTER.ID, CONTENT_ITEM_TYPES.REPORT_SECTION.ID].includes(
        individualResult.type,
      )
    ) {
      if (individualResult.portfolio.urlSlug && individualResult.entity.urlSlug) {
        return `/${entityProfileUrl(individualResult.portfolio.urlSlug, individualResult.entity.urlSlug)}`;
      }
    }
    if (overrideLink) {
      return overrideLink;
    }
    return '';
  };

  /**
   * To handle page number changes for publications. Scrolls up to beginning of list and calls api/local storage to get publication data
   */
  const onPageChange = (page) => {
    innerPageScroll(style.searchResults);
    fetchNewSearchResults(page, false, urlParams.get(URL_QUERY_KEYS.SEARCH_TERM) || '', convertFilterUrlToApi(urlParams));
  };

  const fetchNewSearchResults = async (pageNum, rerunApi, searchQuery, filters) => {
    let newSearchResults = [];
    let totalCount = 0;

    // Takes the current search term given (if not found will take from url)
    let searchTerm;
    if (searchQuery !== null && searchQuery !== undefined) {
      searchTerm = searchQuery;
    } else {
      searchTerm = urlParams.get(URL_QUERY_KEYS.SEARCH_TERM) || '';
    }

    if (rerunApi) {
      removeFromLocalStorage(SEARCH_PAGE_LOCAL_STORAGE_KEY); // rerunning api should remove all data from local (used for either new search term or filters)
    }

    try {
      setLoadingSearchResults(true);
      const { results, totalResults, searchId } = await fetchSearchResults(pageNum, rerunApi, searchTerm, filters);
      console.log(searchId);
      if (searchId) {
        console.log('search ID found');
        trackSearch({
          SearchServiceName: 'acs-dof-shared-aue-01',
          SearchId: searchId,
          IndexName: SEARCH_API_URL.INDEX_NAME(),
          QueryTerms: searchTerm,
          ResultCount: totalResults,
        });
        console.log(appInsights);
      }
      // FIXME: HOTJAR INTEGRATION - After MVP 2 release, at the start of MVP3, hotjar need to be removed as this was only for research purpose by designer between MVP2 and MVP3
      hotjar.initialize(process.env.REACT_APP_HOTJAR_ID);
      newSearchResults = results || [];
      totalCount = totalResults;
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSearchResults(false);
      setSearchResultList(newSearchResults);
      setTotalItems(totalCount);
      setCoverImgLoad(imageLoadingState(newSearchResults)); // set all image loading to true initially
      innerPageScroll(style.searchResults);
    }
  };

  const sendClickAnalytics = (searchItemData, index) => {
    trackClick({
      SearchServiceName: 'acs-dof-shared-aue-01',
      SearchId: searchItemData.searchId,
      ClickedDocId: searchItemData.docId,
      Rank: index,
    });
  };

  const isBodyType = (type) => {
    if (type === BODY_TYPES.COMMONWEALTH_COMPANY || type === BODY_TYPES.CORPORATE_COMMONWEALTH_ENTITY || type === BODY_TYPES.NON_CORPORATE_COMMONWEALTH_ENTITY) return true;
    return false;
  };

  const isReportChapter = (type) => {
    return type === CONTENT_ITEM_TYPES.REPORT_CHAPTER.ID;
  };

  const isReportSection = (type) => {
    return type === CONTENT_ITEM_TYPES.REPORT_SECTION.ID;
  };

  const renderSearchResults = (results) => {
    const searchedTerm = urlParams.get(URL_QUERY_KEYS.SEARCH_TERM) || null;

    return results?.map((data, idx) => {
      const key = `search item key ${idx}`;
      const isPublication = [
        PUBLICATION_TYPE.ANNUAL_REPORT.VALUE,
        PUBLICATION_TYPE.CORPORATE_PLAN.VALUE,
        PUBLICATION_TYPE.PORTFOLIO_BUDGET_STATEMENT.VALUE,
        CONTENT_ITEM_TYPES.REPORT_CHAPTER.ID,
        CONTENT_ITEM_TYPES.REPORT_SECTION.ID,
      ].includes(data.type);
      const hasCoverImageOrLogo = (isPublication && data?.coverImage) || (!isPublication && data?.entity?.logo);
      let entityTags = [];
      let allTags = [];

      data?.tags?.map((tag) => {
        if (
          (isBodyType(tag?.displayText) || tag?.isStatusText) &&
          (isARType(data.type) || isCPType(data.type) || isPBSType(data.type) || isReportChapter(data.type) || isReportSection(data.type))
        )
          entityTags.push(tag);
        else allTags.push(tag);
      });

      // escape values such as slashes and dots or other non-alphabetical extra characters
      const regex = new RegExp(`${searchedTerm?.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')}`, 'gi');
      const summaryToDisplay = searchedTerm
        ? data?.summary?.replace(regex, function replace(match) {
            return `<mark>${match}</mark>`;
          })
        : data?.summary;
      const summary = parse(summaryToDisplay || '');

      const searchItemSection = (
        <Link
          to={searchPageLink(data)}
          onClick={() => sendClickAnalytics(data, idx)}
          className={[style.searchItem, 'alwaysFresh'].join(' ')}
          target={isCPType(data.type) || isPBSType(data.type) ? '_blank' : '_self'}>
          <span className={style.body}>
            <span className={style.tagHolder}>
              {allTags.map((tag, i) => (
                <Tag key={`${key} tag ${i}`} displayText={tag?.displayText} fullText={tag?.fullText} type={tag?.type} />
              ))}
            </span>
            {(isReportSection(data?.type) || isReportChapter(data?.type)) && <span className={style.annualReportSubTitle}>{data?.annualReportTitle}</span>}
            {isReportSection(data?.type) && <br /> && isReportSection(data?.type) && <span className={style.chapterSubTitle}>{data?.chapterTitle}</span>}
            <span className={[style.title, 'cardTitle', 'limitTextLines'].join(' ')} style={{ '--MAX-LINE': 1 }}>
              {data?.title}
              {(isCPType(data.type) || isPBSType(data.type)) && (
                <svg>
                  <use href={icons + '#open-in-new-tab'} />
                </svg>
              )}
            </span>
            {summary.length > 0 && <span className={style.summary}>{summary}</span>}
          </span>
        </Link>
      );

      const entitySection = () => {
        let entityOrPortfolio;

        if (data.type === PUBLICATION_TYPE.PORTFOLIO_BUDGET_STATEMENT.VALUE) {
          entityOrPortfolio = data?.portfolio?.name;
        } else {
          entityOrPortfolio = data?.entity?.name;
        }
        return (
          <span className={[style.entity].join(' ')}>
            <span
              className={[style.tail, style.tailOnePart, isPublication && style.isPublication, data.type === CONTENT_ITEM_TYPES.PORTFOLIO.ID && style.bgImg].join(' ')}
              style={hasCoverImageOrLogo ? null : backgroundImage}>
              <div className={style.entityTagsAndLink}>
                {entityTags.length > 0 &&
                  entityTags.map((tag, entityTagIndex) => {
                    return (
                      <span key={`${key}-${tag.displayText}-${entityTagIndex}`} className={style.tagHolderAR}>
                        <Tag key={`${key}`} displayText={tag?.displayText} fullText={tag?.fullText} type={tag?.type} />
                      </span>
                    );
                  })}
                {isPublication && entityOrPortfolio && (
                  <Link
                    className={['alwaysFresh'].join(' ')}
                    to={[CONTENT_ITEM_TYPES.PORTFOLIO.ID, CONTENT_ITEM_TYPES.GOVERNMENT_BODY.ID].includes(data.type) ? searchPageLink(data) : entitiesLink(data)}>
                    {data.type === PUBLICATION_TYPE.PORTFOLIO_BUDGET_STATEMENT.VALUE ? <span>Portfolio: </span> : ''}
                    <span className={style.entityPortfolioLink}>{entityOrPortfolio}</span>
                  </Link>
                )}
              </div>
              {hasCoverImageOrLogo && (
                <>
                  <LoadingMenu numberOfItems={5} className={[coverImgLoad[idx]?.loading ? style.display : style.hide, style.loadingBackground, 'imgLoading'].join(' ')} />
                  <div
                    className={[
                      style.imgContainer,
                      !isPublication && data?.entity?.logo ? style.entityImage : null,
                      coverImgLoad[idx].loading || coverImgLoad[idx]?.fail ? style.hide : style.display,
                    ].join(' ')}>
                    <img
                      src={isPublication ? data?.coverImage : data?.entity?.logo}
                      alt={isPublication ? `${data?.title} cover image` : `${entityOrPortfolio} logo`}
                      onLoad={() => onImageLoad(idx)}
                      onError={() => onImageError(idx)}
                    />
                  </div>
                </>
              )}
              {/* Default entity logo is shown if no logo is given */}
              {(!hasCoverImageOrLogo || coverImgLoad[idx]?.fail) && data.type === CONTENT_ITEM_TYPES.GOVERNMENT_BODY.ID && (
                <img className={[style.entityImage].join(' ')} src={DefaultEntityLogo} alt="" role="presentation" />
              )}
              {/* default cover image is only shown if it's publication and AR type and cover image isn't available to load */}
              {isPublication && (!data?.coverImage || coverImgLoad[idx]?.fail) && (
                <span className={style.defaultCoverImage}>
                  <DefaultCoverImage entityName={data?.entity?.name} reportTitle={data?.title} />
                </span>
              )}
            </span>
          </span>
        );
      };

      return (
        <li key={key} className={['card', style.resultItem].join(' ')}>
          {viewMode === CONSTANTS.VIEW_MODE.DESKTOP ? (
            <>
              {searchItemSection}
              {entitySection()}
            </>
          ) : (
            <>
              {entitySection()}
              {searchItemSection}
            </>
          )}
        </li>
      );
    });
  };

  return (
    <>
      {!hasErrored && (
        <PageHead pageTitle={PATHS.SEARCH.TITLE}>
          <div id={style.bannerContent}>
            <p>Search the portal for relevant entities, portfolios, publications, or pages.</p>
            <br />
            <form role="search" aria-label="Sitewide">
              <SearchBar takeQuery={true} onClear={searchTermUpdated} onSubmit={searchTermUpdated} />
            </form>
          </div>
        </PageHead>
      )}
      {/* // use Suspense to show loading page before profile page finishes loading */}
      {!hasErrored && (
        <Suspense fallback={<FilterLoading />}>
          <Await
            resolve={homepage}
            errorElement={
              <Filter filterTitle="Filter publications" isDisabled removeFilterFunction={removeFilter} clearFilterFunction={clearFilter} applyFilterFunction={applyFilter}>
                {FILTER_API_FAILED}
              </Filter>
            }>
            {(resolvedData) => {
              const { filterOptions, filterGroupValues } = resolvedData; // home page data (which is needed for filter options)
              if (!filterOptions || !filterGroupValues) {
                return (
                  <Filter filterTitle="Filter publications" isDisabled removeFilterFunction={removeFilter} clearFilterFunction={clearFilter} applyFilterFunction={applyFilter}>
                    {FILTER_API_FAILED}
                  </Filter>
                );
              }

              useEffect(() => {
                const selectedFilterList = [];

                // Add filtered options from url (if any)
                Object.keys(filterOptions).forEach((key) => {
                  // Note: Assumes that values are split by ';'
                  const groupInUrl = urlParams.get(`${URL_QUERY_KEYS.FILTER_DENOTE}${key}`)?.split(URL_QUERY_KEYS.FILTER_VALUES_SPLIT_BY) || [];
                  if (groupInUrl) {
                    filterOptions[key].forEach((dropdownOption) => {
                      if (groupInUrl.includes(dropdownOption.returnValue)) {
                        dropdownOption.selected = true;
                        selectedFilterList.push(dropdownOption);
                      } else {
                        dropdownOption.selected = false;
                      }
                    });
                  }
                });

                setMultiSelectOptions(filterOptions);
                setSelectedFilters(selectedFilterList);
                setPreviouslyAppliedFilters(selectedFilterList);
              }, [pathname, searchLocationData]);
              return (
                <>
                  <Filter
                    filterTitle="Filter search results"
                    filterLabel={`Showing ${searchResultList.length} of ${totalItems || 0} result${totalItems !== 1 ? 's' : ''}`}
                    selectedFilters={selectedFilters}
                    removeFilterFunction={removeFilter}
                    clearFilterFunction={clearFilter}
                    applyFilterFunction={applyFilter}
                    disableApplyButton={arraysAreEqualByProperty(selectedFilters, previouslyAppliedFilters, 'returnValue')}>
                    {Object.keys(filterOptions).map((groupOption, index) => {
                      const filterGroupDisplayName = getFilterGroupDisplayName(groupOption, filterGroupValues);

                      // Default filter is standard filter list
                      let filterListComponent = (
                        <StandardFilterList
                          dropdownOptions={multiSelectOptions[groupOption] || []}
                          selectOptionFunction={(option) => updateSingleMultiSelect(option, groupOption)}></StandardFilterList>
                      );
                      if (groupOption?.toLowerCase() === FILTERABLE_GROUPS.PORTFOLIO.VALUE.toLowerCase()) {
                        // Use SearchFilterList component for 'Portfolio' filter
                        filterListComponent = (
                          <SearchFilterList
                            dropdownOptions={multiSelectOptions[groupOption] || []}
                            selectOptionFunction={(option) => updateSingleMultiSelect(option, groupOption)}
                            searchPlaceholder={`Enter your ${filterGroupDisplayName.toLowerCase()}`}></SearchFilterList>
                        );
                      } else if (groupOption?.toLowerCase() === FILTERABLE_GROUPS.ENTITY.VALUE.toLowerCase()) {
                        // Use Alphabet Group Filter List for 'Entity and companies' filter
                        filterListComponent = (
                          <AlphabetGroupFilterList
                            dropdownOptions={multiSelectOptions[groupOption] || []}
                            selectOptionFunction={(option) => updateSingleMultiSelect(option, groupOption)}
                            selectMultipleOptionFunction={(multipleOptions) => updateMultipleMultiSelect(multipleOptions, groupOption)}
                            searchPlaceholder={`Enter your ${filterGroupDisplayName.toLowerCase()}`}
                            selectAllState={selectAllState}
                            setSelectAllState={setSelectAllState}></AlphabetGroupFilterList>
                        );
                      }

                      return (
                        <FilterGroupDropdown key={`filter-group-dropdown-${groupOption}-${index}`} filterGroupTitle={groupOption} filterGroupDisplayName={filterGroupDisplayName}>
                          {filterListComponent}
                        </FilterGroupDropdown>
                      );
                    })}
                  </Filter>
                </>
              );
            }}
          </Await>
        </Suspense>
      )}
      <Suspense fallback={<SearchPageLoading />}>
        <Await
          resolve={search}
          errorElement={
            <StandardPage setErrorFunction={() => setHasErrored(true)} title={PAGE_NOT_FOUND_TITLE}>
              {SOMETHING_WENT_WRONG}
            </StandardPage>
          }>
          {(resolvedSearchResults) => {
            const { results, totalResults } = resolvedSearchResults;

            useEffect(() => {
              // FIXME: HOTJAR INTEGRATION - After MVP 2 release, at the start of MVP3, hotjar need to be removed as this was only for research purpose by designer between MVP2 and MVP3
              hotjar.initialize(process.env.REACT_APP_HOTJAR_ID);
            }, []);

            useEffect(() => {
              setSearchResultList(results || []);
              setCoverImgLoad(imageLoadingState(results));
              setTotalItems(totalResults);
            }, []);

            // Find total pages (uses totalItems but will use totalResults on first load)
            const totalPages = Math.ceil((totalItems !== null ? totalItems : totalResults || 0) / MAX_SEARCH_RESULTS_PER_PAGE);
            const fallBackMessage = !urlParams.get(URL_QUERY_KEYS.SEARCH_TERM) ? 'No search key provided.' : NO_RESULTS_FOUND;

            if (totalResults === 0) {
              return loadingSearchResults ? (
                <SearchPageLoading />
              ) : (
                <Suspense fallback={<div className={style.message}>{fallBackMessage}</div>}>
                  <Await resolve={metadata} errorElement={<div className={style.message}>{fallBackMessage}</div>}>
                    {(resolvedMetaData) => {
                      return (
                        <div className={style.message}>
                          {parse(!urlParams.get(URL_QUERY_KEYS.SEARCH_TERM) ? resolvedMetaData?.noSearchKeyMessage : resolvedMetaData?.noResultsMessage)}
                        </div>
                      );
                    }}
                  </Await>
                </Suspense>
              );
            }

            return (
              <>
                {loadingSearchResults ? <SearchPageLoading /> : <ul id={style.searchResults}>{renderSearchResults(searchResultList)}</ul>}
                <div className={loadingSearchResults ? style.hidePagination : null}>
                  <Pagination totalPages={totalPages} onPageChange={onPageChange} />
                </div>
              </>
            );
          }}
        </Await>
      </Suspense>
    </>
  );
}
