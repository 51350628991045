export const PUBLICATION_TYPE = {
  ANNUAL_REPORT: { VALUE: 'annual_report', TAG: 'AR' },
  ANNUAL_REPORT_GROUP: { VALUE: 'annual_report|report_chapter|report_sections', TAG: 'AR' },
  PORTFOLIO_BUDGET_STATEMENT: { VALUE: 'pbs', TAG: 'PBS' },
  CORPORATE_PLAN: { VALUE: 'corp_plan', TAG: 'CP' },
  PORTFOLIO: { VALUE: 'portfolio_metadata' },
};

export const MAX_PUBLICATION_PER_PAGE = 16;

export const PUBLICATIONS_LIST_ID = 'publicationsList';
